import React  from 'react';
import './Info.scss'

function Info(props) {


    return (
        <div className="main-container text-center info-container">
            <h2>What is the Big Red Button?</h2>
            <p>
                The Big Red Button &copy; (BRB) is a financial investment ejection button. Once configured, if pressed, it will sell all the crypto currency positions in your portfolio to the chosen endpoint and or target currency.
                This system is designed to be used in situations where executing a pull out strategy is of value.
            </p>
            <h3>How to use it?</h3>
            <p>
                Once the proper wallets are configured using MetaMask, you may press the button and follow the prompt to initiate your exodus. We are currently in alpha stage.
            </p>
        </div>
    );
}

export default Info;
