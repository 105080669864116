import React, {useState } from 'react';
import './MetaMask.scss';
import Button from '@material-ui/core/Button';

import {  ReactComponent as  MetaMaskLogo} from './../../assets/svg/result.svg';


function MetaMask(props) {

    const ethereum = window.ethereum;

    const [addr, setAddr] = useState('Not Connected');

    if (ethereum) {
        ethereum.on('accountsChanged', (accounts) => {
            setAddr(accounts[0]);
            props.updateCurrentAddress(accounts[0]);
        });
    }


    function connectToMetaMask(){
        // const accounts = ethereum.request({ method: 'eth_requestAccounts' });
        // console.log(accounts[0]);
        setAddr(ethereum.selectedAddress)
        props.updateCurrentAddress(ethereum.selectedAddress);

    }

    return (
        <div>
            {ethereum &&
                <div className="d-flex flex-column align-items-center metamask-wrapper">

                    {addr !== 'Not Connected' && <h3 className="mb-0">{addr}</h3> }
                    {addr === 'Not Connected' &&
                        <Button variant="contained" color="primary" onClick={connectToMetaMask}>
                            Connect to MetaMask <MetaMaskLogo height="30"/>
                        </Button>
                    }

                </div>

            }
            {!ethereum && <p>Please Use MetaMask to continue.</p>}

        </div>
    );
}

export default MetaMask;
