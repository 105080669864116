import React, {useState} from 'react';
// import logo from './logo.svg';
import './App.scss';
import MetaMask from "./components/metamask/MetaMask";
import Header from "./components/Header/Header";
import EtherScan from "./components/etherscan/etherscan";
import Container from '@material-ui/core/Container';
import Info from "./components/Info/Info";


function App() {

    const [currentAddress, setCurrentAddress] = useState('0xed91879919b71bb6905f23af0a68d231ecf87b14');

    function updateCurrentAddress(newAddr) {
        setCurrentAddress(newAddr);
        console.log(newAddr);
    }

    const link = '#';

  return (
    <div className="App">
     <Header />
        <Container maxWidth="lg">
            <div className="d-flex justify-content-center">
                <MetaMask updateCurrentAddress={updateCurrentAddress} currentAddress={currentAddress}/>
            </div>
            <div className="d-flex w-100 justify-content-center flex-column align-items-center">
                <h1 className="text-center">Eject To ETH</h1>
                <div className="purgeButton-wrapper">
                    <a className="purgeButton" href={link}><span className="d-none">.</span></a>
                </div>
                <EtherScan currentAddress={currentAddress}  />
            </div>
            <Info />
        </Container>
    </div>
  );
}

export default App;
