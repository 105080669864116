import React, {useState, useEffect} from 'react';
import List from '@material-ui/core/List';
import './etherscan.scss';
import FormSwitch from "../elements/FormSwitch/FormSwitch";
import IconButton from '@material-ui/core/IconButton';

import LockOpenIcon from '@material-ui/icons/LockOpen';
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import MenuItem from "@material-ui/core/MenuItem";

function EtherScan(props) {

    const [addrTokens, setTokens] = useState({});
    const ethplorerApiKey = 'EK-hx5nq-8ox5dWd-L5mod'

    useEffect(() => {
        fetch(`https://api.ethplorer.io/getAddressInfo/${props.currentAddress}?apiKey=${ethplorerApiKey}`)
            .then(results => results.json())
            .then(data => {
                setTokens(data.tokens);
                console.log(data.tokens);
            });
    }, [props.currentAddress]);

    function moveDecimal(n, dec) {
        var v = n/Math.pow(10, dec);
        v = v.toFixed(8).replace(/(\.0+|0+)$/, '');
        return v;
    }

    function renderTokenList () {
            return addrTokens.map(function (token, index) {
            return(
                <li className={`token-list__item ${index > 2 ? 'token-list__item__lock':''}`} key={index}>
                    <div className="d-flex w-100">
                            <div className="d-flex justify-content-between w-100 align-items-center">
                                <div className="d-flex align-items-center">
                                    <img height="40" width="40" src={token.tokenInfo.image ? 'https://ethplorer.io/'+token.tokenInfo.image : '/img/question-mark-150x150.png'} alt=""/>
                                    <div>
                                        <h5 className="mb-0">{token.tokenInfo.symbol === '' ? 'Not Available' : token.tokenInfo.symbol }</h5>
                                        <div className="sub-text">{token.tokenInfo.name === '' ? 'No Name Found' : token.tokenInfo.name }</div>
                                        <div className="sub-text">
                                            <a href={`https://etherscan.io/address/${token.tokenInfo.address}`} target="_blank" rel="noreferrer">
                                            {token.tokenInfo.address.slice(0, 4)+"..."+token.tokenInfo.address.substr(token.tokenInfo.address.length - 4)}
                                        </a></div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center">
                                    {moveDecimal(token.balance ,token.tokenInfo.decimals)}
                                    <div className="token-list__item__lock-hide">
                                        <FormSwitch />
                                    </div>
                                    <IconButton
                                        aria-label="Unlock"
                                        aria-haspopup="true"
                                        color="success"
                                        className="token-list__item__lock-show"
                                    >
                                        <LockOpenIcon />
                                    </IconButton>
                                </div>
                            </div>
                       </div>
                </li>
            )
        });

    }


    return (
        <React.Fragment>
            { addrTokens &&
                <React.Fragment>
                    <h4>Coins to Purge:</h4>
                    <div className="etherscan-list">
                        <List component="nav" aria-label="contacts">
                            {addrTokens && addrTokens.length > 0 ? renderTokenList() : ''}
                        </List>
                    </div>
                </React.Fragment>
            }
            {
                !addrTokens &&
                    <p>No Tokens Found on this address...</p>
            }
        </React.Fragment>
    );
}

export default EtherScan;
