import React  from 'react';

import Switch from '@material-ui/core/Switch';

function FormSwitch() {
    // Similar to componentDidMount and componentDidUpdate:

    const [checked, setChecked] = React.useState(false);

    const handleChange = (event) => {
        setChecked(!checked)
    };

    return (
        <Switch
            checked={checked}
            onChange={handleChange}
            name="checkedB"
            color="primary"
        />
    );
}

export default FormSwitch;
